(function(d) {
    var resume = {
        init: function() {
            let targets = d.querySelectorAll('[data-vid-cont]');
            if (__tnt.user.loggedIn) {
                // Observer callback
                let callback = function(entries, io) {
                    entries.forEach(function(entry) {
                        if (entry.isIntersecting === true) {
                            // Run block's video fetch/populate
                            resume.fetch(entry.target);
                            // Kill observer
                            io.unobserve(entry.target);
                        }
                    });
                };
                // Initialize observer
                let config = { rootMargin: "0px 0px 300px" },
                    io = new IntersectionObserver(callback, config);
                // Set observers
                targets.forEach(function(el) {
                    io.observe(el);
                });
            }
        },
        preview: function() {
            return window.location.pathname.indexOf('/tncms/admin/action/main/preview/site');
        },
        previewScrub: function(content) {
            content.querySelectorAll('.tnt-asset-link').forEach(function(link) {
                let href = link.getAttribute('href');
                link.setAttribute('href', __tnt.scrubUrl(href) + '#tncms-source=block-continue_watching');
            });
            return content;
        },
        fetch: function(container) {
            // Fetch recent markers
            fetch(__tnt.scrubUrl('/tncms/video/get_recent_markers/?limit=' + container.dataset.limit)).then(function(response) {
                response.json().then(function(recent_markers) {
                    if (recent_markers.total <= 0) return;
                    let urls = recent_markers.results.map(function(data) {
                            return __tnt.scrubUrl('/video_' + data.asset_id + '.json?card=summary&seconds=' + data.seconds + '&bl=' + container.dataset.vidCont);
                        }),
                        requests = urls.map(function(url) {
                            return fetch(url);
                        });
                    // Request assets 
                    Promise.all(requests).then(function(responses) {
                        return responses;
                    }).then(function(responses) {
                        Promise.all(responses.map(function(r) {
                            return r.json();
                        })).then(function(assets) {
                            // Append returned cards to container
                            assets.forEach(function(asset) {
                                let card = d.createRange().createContextualFragment(asset.card);
                                if (resume.preview !== -1) card = resume.previewScrub(card);
                                container.querySelector('.continue-watching').appendChild(card);
                            });
                            // Reveal content
                            container.querySelector('.hidden').classList.remove('hidden');
                        });
                    });
                });
            }).catch(function(err) {
                __tnt.log("Fetch Error: ", err);
            });
        }
    };
    if (d.readyState == 'loading') {
        d.addEventListener('DOMContentLoaded', function() {
            resume.init();
        });
    } else {
        resume.init();
    }
})(document);